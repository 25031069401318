import { FC, useEffect } from 'react'
import {
  AppAnalytics,
  useAppCues,
  ErrorBoundary,
  initLocalization,
  ShadowUserBanner,
  AppConfigProvider,
  QAInfoPopover,
  ProdWarningModal,
  useIsThirdGen,
} from '@signifyd/components'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { devToolsTokens } from '@signifyd/sig-keys'
import { legacyTheme, thirdGenTheme } from '@signifyd/ant'
import locales from 'locales'
import AppRoutes from 'AppRoutes'
import { User } from '@signifyd/http'
import { UserConfigs } from '@signifyd/app-feature-configs'

initLocalization(locales)

interface Props {
  user: User
  userConfigs: UserConfigs
}

const AppContainer: FC<Props> = ({ user, userConfigs }) => {
  const isThirdGen = useIsThirdGen()

  const themeClassName = isThirdGen ? 'thirdGen' : 'secondGen'

  // Tooltips, popovers, and similar elements in ant are nested under a separate top level section under the body, this targets those too
  useEffect(() => {
    document.body.classList.add(themeClassName)

    return () => {
      document.body.classList.remove('thirdGen', 'secondGen')
    }
  }, [themeClassName])

  useAppCues({
    user,
    appName: 'customer-dev-tools',
    allowedBuildEnvs: ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  return (
    <AppConfigProvider
      theme={isThirdGen ? thirdGenTheme : legacyTheme}
      user={user}
      userConfigs={userConfigs}
    >
      <ErrorBoundary>
        <>
          <AppAnalytics
            user={user}
            logRocketId={devToolsTokens.logRocketId}
            heapAppId={devToolsTokens.heapAppId}
            enabled={process.env.BUILD_ENV === 'production'}
          />
          <AppRoutes user={user} />
          <ReactQueryDevtools initialIsOpen={false} />
          <ShadowUserBanner currentUser={user} />
          <ProdWarningModal />
          <QAInfoPopover />
        </>
      </ErrorBoundary>
    </AppConfigProvider>
  )
}

export default AppContainer
