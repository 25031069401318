import { FC } from 'react'
import {
  P,
  T2,
  T4,
  ExternalLink,
  Space,
  useIsThirdGen,
  TextThirdGen,
} from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { Card, Divider, Typography } from 'antd'
import { colorSnow } from '@signifyd/colors'
import PageWrapper from 'core/components/PageWrapper'
import HeaderBar from 'core/components/HeaderBar/HeaderBar'
import TeamOverviewCard from './components/TeamOverviewCard'
import ResourcesPageSection from './components/ResourcesPageSection'
import styles from './ResourcesPage.less'
import ResourcesPageSectionThirdGen from './components/ResourcesPageSection/ResourcesPageSectionThirdGen'

const ResourcesPage: FC = () => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <div className={styles.pageContainer}>
        <PageWrapper backgroundColor={colorSnow}>
          <section
            className={styles.resourcesPage}
            data-test-id="resources-page"
          >
            <T2 className={styles.title}>{t('resourcesPage.title')}</T2>

            <Space size="md" />

            <ResourcesPageSection
              title={t('resourcesPage.integration.title')}
              subTitle={t('resourcesPage.integration.subTitle')}
              description={{
                preLinkText: t(
                  'resourcesPage.integration.description.preLinkText'
                ),
                url: 'https://developer.signifyd.com/ecommerce-integration-guide/',
                linkText: t('resourcesPage.integration.description.linkText'),
                postLinkText: t(
                  'resourcesPage.integration.description.postLinkText'
                ),
              }}
            />

            <Divider />

            <ResourcesPageSection
              title={t('resourcesPage.enterprise.title')}
              subTitle={t('resourcesPage.enterprise.subTitle')}
              description={{
                preLinkText: t(
                  'resourcesPage.enterprise.description.preLinkText'
                ),
                url: 'https://signifydenterprise.docs.apiary.io',
                linkText: t('resourcesPage.enterprise.description.linkText'),
                postLinkText: t(
                  'resourcesPage.enterprise.description.postLinkText'
                ),
              }}
            />

            <Space size="md" />

            <TeamOverviewCard />

            <Space size="md" />

            <T4 className={styles.quickLinksTitle}>
              {t('resourcesPage.quickLinks.title')}
            </T4>

            <Space size="md" />

            <P>
              <strong>{t('resourcesPage.quickLinks.subTitle')}</strong>
            </P>

            <P>
              {t('resourcesPage.quickLinks.description.preLinkText')}{' '}
              <ExternalLink
                url="https://developer.signifyd.com"
                target="_blank"
              >
                {t('resourcesPage.quickLinks.description.linkText')}
              </ExternalLink>{' '}
              {t('resourcesPage.quickLinks.description.postLinkText')}
            </P>
          </section>
        </PageWrapper>
      </div>
    )
  }

  return (
    <PageWrapper>
      <section data-test-id="resourcesPage">
        <HeaderBar title={t('resourcesPage.title')} />

        <div className={styles.container}>
          <Card bordered={false}>
            <ResourcesPageSectionThirdGen
              title={t('resourcesPage.integration.title')}
              subTitle={t('resourcesPage.integration.subTitle')}
              description={{
                preLinkText: t(
                  'resourcesPage.integration.description.preLinkText'
                ),
                url: 'https://developer.signifyd.com/ecommerce-integration-guide/',
                linkText: t('resourcesPage.integration.description.linkText'),
                postLinkText: t(
                  'resourcesPage.integration.description.postLinkText'
                ),
              }}
            />

            <Space size="md" />
            <Divider />
            <Space size="md" />

            <ResourcesPageSectionThirdGen
              title={t('resourcesPage.enterprise.title')}
              subTitle={t('resourcesPage.enterprise.subTitle')}
              description={{
                preLinkText: t(
                  'resourcesPage.enterprise.description.preLinkText'
                ),
                url: 'https://signifydenterprise.docs.apiary.io',
                linkText: t('resourcesPage.enterprise.description.linkText'),
                postLinkText: t(
                  'resourcesPage.enterprise.description.postLinkText'
                ),
              }}
            />
          </Card>

          <Space size="md" />

          <TeamOverviewCard />

          <Space size="md" />

          <Card bordered={false}>
            <Typography.Title level={3} className={styles.quickLinksTitle}>
              {t('resourcesPage.quickLinks.title')}
            </Typography.Title>

            <Space size="md" />

            <TextThirdGen weight="semibold">
              {t('resourcesPage.quickLinks.subTitle')}
            </TextThirdGen>

            <TextThirdGen paragraph>
              {t('resourcesPage.quickLinks.description.preLinkText')}{' '}
              <ExternalLink
                url="https://developer.signifyd.com"
                target="_blank"
              >
                {t('resourcesPage.quickLinks.description.linkText')}
              </ExternalLink>{' '}
              {t('resourcesPage.quickLinks.description.postLinkText')}
            </TextThirdGen>
          </Card>
        </div>
      </section>
    </PageWrapper>
  )
}

export default ResourcesPage
