import { FC } from 'react'
import { Space, useIsThirdGen } from '@signifyd/components'
import styles from './SearchDisplay.less'

const SearchDisplay: FC = ({ children }) => {
  const isThirdGen = useIsThirdGen()

  return (
    <>
      <div className={styles.container}>{children}</div>
      <Space size={isThirdGen ? 'md' : 'lg'} />
    </>
  )
}

export default SearchDisplay
