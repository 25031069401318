import {
  FeatureFlagConfig,
  FeatureFlagController,
  PageSpinnerThirdGen,
  thirdGenTreatmentKey,
} from '@signifyd/components'
import { User } from '@signifyd/http'
import { FC } from 'react'

interface Props {
  user: User
}

export const featureFlagConfig: Array<FeatureFlagConfig> = [
  {
    key: thirdGenTreatmentKey,
    label: 'Third Gen',
  },
] as const

const DevToolsFeatureFlagController: FC<Props> = ({ user, children }) => {
  return (
    <FeatureFlagController
      user={user}
      featureFlagConfig={featureFlagConfig}
      LoadingComponent={<PageSpinnerThirdGen />}
    >
      {children}
    </FeatureFlagController>
  )
}

export default DevToolsFeatureFlagController
