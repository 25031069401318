import { FC } from 'react'
import { Flex } from 'antd'
import { spacingLG } from '@signifyd/ant'
import TitleThirdGen from '@signifyd/components/src/thirdGenComponents/TitleThirdGen/TitleThirdGen'
import styles from './HeaderBar.less'

interface Props {
  title: string
}

const HeaderBar: FC<Props> = ({ title, children }) => {
  return (
    <Flex className={styles.header} align="center" justify="space-between" wrap>
      <TitleThirdGen>{title}</TitleThirdGen>
      <Flex justify="flex-start" gap={spacingLG} wrap>
        {children}
      </Flex>
    </Flex>
  )
}

export default HeaderBar
